import Link from "next/link";
import styles from "./LogoCarousel.module.scss";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CloudinaryImage } from "../../Atoms/Cloudinary";

export default function LogoCarousel() {
  const responsive = {
    desktop: {
      breakpoint: { max: 6000, min: 1024 },
      items: 5,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
  };

  return (
    <div
      style={{
        marginBottom: 20,
      }}
    >
      <h3
        className="UIT UITSLL UITW1 UITC0"
        style={{
          marginTop: 40,
        }}
      >
        Recommended By
      </h3>
      <p
        style={{
          marginBottom: 40,
          fontSize: 14,
        }}
      >
        Featured by top medias around the world!
      </p>
      <Carousel
        ssr={false}
        infinite
        responsive={responsive}
        showDots={false}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay={false}
        pauseOnHover={true}
        transitionDuration={2000}
        customTransition={"all 2s linear"}
        autoPlay={true}
        autoPlaySpeed={2}
        arrows={false}
        centerMode={true}
        swipeable
        draggable
        keyBoardControl
      >
        {logos.map((logo, index) => {
          return (
            <div key={index} className={styles.LogoItem}>
              <Link href={logo.url} target="_blank">
                <CloudinaryImage
                  src={logo.src}
                  transformations={["h_80", "c_scale"]}
                />
              </Link>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}

const logos = [
  {
    src: "https://res.cloudinary.com/dbm1qiew0/image/upload/v1699840409/recruit/media%20logos/forbes-logo-black-transparent.png",
    url: "https://www.forbes.com/sites/davidhochman/2023/05/06/discover-the-best-of-kyotos-cuisine-with-magical-trips-nighttime-food-tour/?sh=34f2fbd3502b",
  },
  {
    src: "https://res.cloudinary.com/dbm1qiew0/image/upload/v1699840410/recruit/media%20logos/cnn.travel.jpg",
    url: "https://edition.cnn.com/travel/article/top-tours-japan/index.html",
  },
  {
    src: "https://res.cloudinary.com/dbm1qiew0/image/upload/v1720057169/recruit/media%20logos/TC_BOTB_mustard_winner-gif_L_2024.gif",
    url: "https://www.tripadvisor.com/Attraction_Review-g298564-d14203033-Reviews-Magical_Trip-Kyoto_Kyoto_Prefecture_Kinki.html",
  },
  {
    src: "https://res.cloudinary.com/dbm1qiew0/image/upload/v1699840409/recruit/media%20logos/image_1.jpg",
    url: "https://www.timeout.com/tokyo/things-to-do/five-reasons-why-you-should-book-a-local-guided-tour-with-magical-trip",
  },
  {
    src: "https://res.cloudinary.com/dbm1qiew0/image/upload/v1699840411/recruit/media%20logos/scmp_logo.png",
    url: "https://www.scmp.com/lifestyle/travel-leisure/article/2123160/discover-real-tokyo-after-dark-new-bar-hopping-tour-and",
  },
  {
    src: "https://res.cloudinary.com/dbm1qiew0/image/upload/v1699840410/recruit/media%20logos/go-tokyo-the-official-tokyo-travel-guide-logo-6EC64D1281-seeklogo.com.png",
    url: "https://www.gotokyo.org/en/new-and-now/new-and-trending/190408/topics.html",
  },
];
