import TourCard from "../Tour";
import styles from "./Poster.module.scss";
import { getCurrency } from "../../../utils/getCurrency";
import { useEffect, useState } from "react";
import dynamic from "next/dynamic";
const RenderClientSideProducts = dynamic(() => import("./ClientSidePosters"), {
  ssr: false,
});

interface Props {
  offset?: number;
  limit?: number;
  locationId?: string;
  parameter?: string;
  preloadProducts?: any;
  ids?: string[];
}

export default function PosterCard({
  offset,
  limit,
  locationId,
  parameter,
  preloadProducts,
  ids,
}: Props) {
  const [currencyCode, setCurrencyCode] = useState("USD");
  useEffect(() => {
    setCurrencyCode(getCurrency());
  }, []);

  return (
    <div className={styles.PostersContainer}>
      {preloadProducts &&
      preloadProducts.length > 0 &&
      currencyCode === "USD" ? (
        preloadProducts.map((product: any, index: any) => {
          return (
            <div key={index} className={styles.PosterCard}>
              <TourCard {...product} parameter={parameter} />
            </div>
          );
        })
      ) : (
        <RenderClientSideProducts
          offset={offset}
          limit={limit}
          locationId={locationId}
          ids={ids}
          parameter={parameter}
        />
      )}
    </div>
  );
}
