import Link from "next/link";
import { gql } from "@apollo/client";
import { CloudinaryImage } from "../../Atoms/Cloudinary";
import Price from "./Price";
import TripAdvisorLabel from "./TripAdvisorLabel";
import { useAnalyticsTracker } from "../../Atoms/Analytics";
import styles from "./Tour.module.scss";
import { FaRegClock } from "react-icons/fa";
import { formatDate, addDuration } from "../../../utils/calendarUtils";
import { Product } from "../../../graphql/operations";
const posterSize = {
  width: 210,
  height: 319,
};

type ReviewRatingProps = Pick<Product, "reviews">;

export function ReviewRating({ reviews }: ReviewRatingProps) {
  if (!reviews.avgStars) {
    return null;
  }

  const displayRating = reviews.avgStars.toFixed(2);

  return (
    <>
      <span className={styles.ProductCardRating}>
        ★ <b style={{ color: "#000" }}>{displayRating}</b>
      </span>{" "}
      ({reviews.totalRecords})
    </>
  );
}

type TourCardProps = Pick<
  Product,
  "id" | "translation" | "duration" | "poster" | "tags" | "price" | "reviews"
> & { parameter?: string };

export default function TourCard({
  id,
  reviews,
  price,
  poster,
  tags,
  translation,
  duration,
  parameter,
}: TourCardProps) {
  const tracker = useAnalyticsTracker();
  const refParam = parameter !== undefined ? `?ref=${parameter}` : "";

  return (
    <div
      className={styles.ProductCard}
      onClick={() =>
        tracker.trackEvent({
          category: "onpage",
          action: "click-poster",
          label: `Id-${id}`,
        })
      }
    >
      <Link href={`/product/${id}${refParam}`}>
        <div className={styles.ProductCardImage}>
          <CloudinaryImage
            src={poster}
            transformations={[
              `w_${posterSize.width}`,
              `h_${posterSize.height}`,
            ]}
          />
          <div className={styles.ProductCardLabel}>
            <TripAdvisorLabel productId={id} />
          </div>
        </div>
        <div className={styles.ProductCardContent}>
          <div className={styles.CityTourCardDetails}>
            <h3 className={styles.ProductCardName}>{translation?.name}</h3>

            {tags && tags.length > 0 && (
              <>
                <span className={styles.ProductCardTag}>
                  #{tags[0].translation?.name}
                </span>
                {tags.length > 1 && (
                  <span className={styles.ProductCardTag}>
                    #{tags[1].translation?.name}
                  </span>
                )}
              </>
            )}
          </div>

          <div className={styles.DurationPrice}>
            <div className={styles.DDContainer}>
              {reviews?.avgStars && reviews.totalRecords > 0 && (
                <span className={styles.ProductCardReviewRating}>
                  <ReviewRating reviews={reviews} />{" "}
                </span>
              )}
              <span>
                <FaRegClock
                  color="#F33F3A"
                  style={{ position: "relative", top: 2, marginRight: 5 }}
                />
                {formatDuration(duration)}
              </span>
            </div>
          </div>

          <div style={{ paddingTop: 2, paddingBottom: 2 }}>
            {price && <Price id={id} price={price} />}
          </div>
        </div>
      </Link>
    </div>
  );
}

function formatDuration(durationInMinutes: number) {
  const durationInHours = durationInMinutes / 60;
  const isExact = durationInMinutes % 60 === 0;
  const formatted = isExact ? durationInHours : durationInHours.toFixed(1);

  return `${formatted} hours`;
}
