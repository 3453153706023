import { useEffect, useState } from "react";
import Poster from "../Poster";

export default function RecentlyViewed() {
  const [viewedItems, setViewedItems] = useState<string[]>([]);

  useEffect(() => {
    if (window) {
      let storedItems = JSON.parse(localStorage.getItem("viewedItems"));

      if (storedItems && storedItems.length > 0) {
        setViewedItems(storedItems);
      }
    }
  }, []);

  return (
    <>
      {viewedItems && viewedItems.length > 0 ? (
        <div style={{ marginBottom: -10 }}>
          <h2
            className="UIT UITSL UITW1 UITC0 HomeCityHeader"
            style={{ marginBottom: 20 }}
          >
            Recently Viewed Tours
          </h2>
          <RecentlyViewedItems items={viewedItems} />
        </div>
      ) : null}
    </>
  );
}

interface ViewItemsProps {
  items: string[];
}

function RecentlyViewedItems({ items }: ViewItemsProps) {
  return (
    <div className="RecentlyViewedTours">
      <Poster ids={items} />
    </div>
  );
}
