import { gql, useQuery } from "@apollo/client";
import dynamic from "next/dynamic";
import { useEffect, useMemo, useState } from "react";
import { BsFillPersonFill, BsPeopleFill } from "react-icons/bs";
import { Product } from "../../../../graphql/operations";
import { getCurrency } from "../../../../utils/getCurrency";
import Loader from "../../../Atoms/Loader";
import styles from "./Price.module.css";
const Money = dynamic(() => import("../../../Atoms/Money"), { ssr: false });

export default function Price({ id, price }) {
  const currencyCode = getCurrency();
  const [amount, setAmount] = useState<number>(Number(price.amount));

  const { loading, error, data } = useQuery<{ product: Product }>(GET_PRICE, {
    fetchPolicy: "cache-and-network",
    variables: {
      currency: currencyCode,
      id: id,
    },
  });

  useEffect(() => {
    if (!loading && data && data.product) {
      setAmount(Number(data.product.mainPrice.amount));
    }
  }, [loading, data]);

  const product = useMemo(() => data?.product, [data]);

  const Icon = useMemo(
    () => (product?.mainPrice.isGroup ? BsPeopleFill : BsFillPersonFill),
    [product]
  );

  if (loading) return <Loader />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className={styles.ProductCardPrice}>
      <div>
        <span className={styles.ProductCardPriceToPayNormal}>
          <Money amount={amount} currency={currencyCode} /> /{" "}
          <Icon style={{ position: "relative", top: 2 }} />
        </span>
      </div>
    </div>
  );
}

export const GET_PRICE = gql`
  query ProductPriceQuery($currency: Currency!, $id: ID!) {
    product(id: $id) {
      id
      availabilityType

      mainPrice(currency: $currency) {
        amount
        currency
        isGroup
      }
    }
  }
`;
