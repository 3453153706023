import styles from "./TripAdvisorLabel.module.css";

interface Props {
  productId: string;
}

export default function TripAdvisorLabel({ productId }: Props) {
  switch (productId) {
    case "59afbefb-b0d9-4e10-9273-b6c59b81e345": {
      return (
        <span className={`${styles.TextLabel} ${styles.TextLabelTripAdvisor}`}>
          #1 Night Food Tour by MagicalTrip
        </span>
      );
    }
    default:
      return null;
  }
}
